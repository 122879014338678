@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500&display=swap');

body {
    font-family: 'Manrope', sans-serif;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Figtree', sans-serif;
    font-weight: 700;
}

.logo-font {
    font-family: 'Figtree', sans-serif;
}

.nav {
    font-family: 'Manrope', sans-serif;
}

.bg-dark {
    background-color: #01021E;
}
.bg-accent {
    background-color: #FF7F12;
}
.bg-white {
    background-color: #ffffff;
}
.bg-black {
    background-color: #000000;
}